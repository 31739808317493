/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class TravelHospitalityContent extends BaseComponent {
  className = 'ts-TravelHospitalityContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>Identity Verification for Travel & Hospitality </h6>
              <h2>Expedite Guest Check-Ins and Enhance Security </h2>
              <p>
                Elevate your guest experience with Trust Stamp's expeditious
                identity verification solution. Instantly confirm guest
                identities with a privacy-preserving selfie, streamlining
                check-in and mitigating fraud risks. Enhance guest satisfaction
                and bolster security.
              </p>
              <br />
              <Link to={ ROUTES.SCHEDULE_DEMO } className="ts-button--filled">Book Demo</Link>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img
                src={ asset`ai/travel-hospitality.svg` }
                alt="Privacy and data protection"
                width="217px"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
