/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import Cards from 'components/Cards';
import { TITLE, CONTENT, CONTENT2 } from './constant';
import help from 'models/help';

export default class AccountRecovery extends BasePage {
  className = 'ts-AccountRecovery';
  static title = 'Secure Account Recovery with Privacy-Preserving Biometrics';
  static description = 'Account recovery should be as robust as authentication. Trust Stamp\'s Stable IT2 binds biometrics with a passkey to maintain secure, multi-factor protection, eliminating reliance on vulnerable single-factor methods like recovery links to email. It ensures rightful users regain access while closing security gaps. Protect against account takeover fraud and elevate user experience with privacy-first biometrics.';
  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="globe" size="small">
          <h1>Account Recovery</h1>
        </Banner>

        <section className={ this.cn`__documents` }>
          <div className={ this.cn`__documents-content ts-container` }>
            <div className={ this.cn`__documents-left-content` }>
              <h6>Secure account recovery</h6>
              <h2>
                Secure Account Recovery with Privacy-Preserving Biometrics
              </h2>
              <p className="ts-p">
                Account recovery should be as robust as authentication. Trust Stamp's Stable IT2 binds biometrics with a passkey to maintain secure, multi-factor protection, eliminating reliance on vulnerable single-factor methods like recovery links to email. It ensures rightful users regain access while closing security gaps. Protect against account takeover fraud and elevate user experience with privacy-first biometrics.
              </p>
              <br />
              <Link to={ ROUTES.SCHEDULE_DEMO } className="ts-button--filled">
                BOOK DEMO
              </Link>
            </div>
            <div className={ this.cn`__documents-right-content` }>
              <img
                src={ asset`ai/account_recovery.svg` }
                alt="Trust Stamp - Age Estimation"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="ts-container">
            <h2>
              Why Choose Trust Stamp’s Stable IT2 for Account Recovery?
            </h2>
          </div>
          <Cards content={ CONTENT2 } columnCount={ 4 } />
        </section>

        <Benefits
          subTitle="3 SIMPLE STEPS"
          sectionTitle={ TITLE }
          content={ CONTENT }
          colored={ true }
        />

        <section>
          <div className="ts-container">
            <h6>See it in action</h6>
            <h2>Schedule a Personalized Demo Today</h2>
            Discover how our privacy-preserving MFA solution can help you prevent account takeovers, enhance user experience, and safeguard your organization’s bottom line. Let us show you the difference.
            <br />
            <br />
            <Link to={ ROUTES.SCHEDULE_DEMO } className="ts-button--primary">
              BOOK A DEMO
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
