/* eslint-disable max-len */
export const TITLE = 'How Does It Work?';

export const CONTENT = [
  {
    id: 1,
    title: 'Biometric Binding',
    text: 'Users’ unique biometrics (e.g., facial scan) are securely bound to their identity, ensuring physical presence is required for account recovery.',
    slug: 'biometric_binding',
    slugType: 'svg',
  },
  {
    id: 2,
    title: 'Passkey Authentication',
    text: 'A secure passkey is stored on a trusted device. Recovery requires both the passkey and live biometric verification, ensuring multi-factor security.',
    slug: 'passkey_authentication',
    slugType: 'svg',
  },
  {
    id: 3,
    title: 'Seamless Interoperability',
    text: 'Recovery works across any trusted device with a front-facing camera, so users can regain access even if they lose or replace their primary device.',
    slug: 'seamless_interoperability',
    slugType: 'svg',
  }
];

export const CONTENT2 = [
  {
    id: 1,
    title: 'Eliminate Weak Links',
    text: 'Traditional recovery methods like security questions, email resets, and OTPs are susceptible to phishing, SIM swaps, and AI-driven fraud. Stable IT2 strengthens security with a multi-factor solution that protects against account takeovers.',
    slug: 'eliminate_links',
  },
  {
    id: 2,
    title: 'No More Lockouts for Rightful Owners',
    text: 'A robust recovery process should secure accounts without blocking legitimate users. Trust Stamp’s Stable IT2 uses biometrics and passkeys to verify rightful users, eliminating the risks of forgotten passwords or outdated contact info. It’s secure, seamless, and user-friendly—delivering access without compromise.',
    slug: 'lockouts',
  },
  {
    id: 3,
    title: 'Cut Costs and Save Time',
    text: 'Manual recovery processes strain support teams and budgets. Stable IT2 empowers users to recover accounts independently, reducing operational overhead and improving efficiency.',
    slug: 'save_time',
  },
  {
    id: 4,
    title: 'Built for Privacy and Trust',
    text: 'With Stable IT2, biometric data stays on the user’s device. Sensitive information never leaves their control, ensuring compliance with privacy standards while fostering user confidence.',
    slug: 'privacy_trust',
  }
];
