import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'constants';
import BasePage from 'pages/BasePage';
import InternalForm from './InternalForm';


const USE_EMERGENT_IFRAME = false;

export default class BookDemo extends BasePage {
  className = 'ts-BookDemo';
  static title = 'Schedule Demo';
  // eslint-disable-next-line max-len
  static description =
    'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  static propTypes = {
    thankYou: PropTypes.bool,
  };

  static defaultProps = {
    thankYou: false,
  };

  state = {};

  // --------------- we might need this code in the near futur ------------------------
  // handlePositionChange = (event, location, label) => {
  //   event.preventDefault();

  //   scrollTo(this.map, 500, -100);
  // };

  performSuccessRedirect = () => {
    this.navigate(ROUTES.CONTACT_US_THANK_YOU);
  };

  performSuccessRedirectPardot = (event) => {
    if (event.data === 'pardot_form_success') {
      this.performSuccessRedirect();
    }
  };

  handleDone = () => {
    this.setState({ thankYou: true });
  };

  renderText = () => {
    return (
      <div>
        <h1 className={ this.cn`__hero__title` }>
          Explore Trusted Identity Solutions – Book Your Demo Today.
        </h1>
        <p className={ this.cn`__form__title` }>
          See how our technology protects identities, reduces fraud, and boosts transaction security for enterprises like yours.
        </p>
      </div>
    );
  };

  renderThankYou = () => {
    return (
      <div>
        <h3 className={ this.cn`__form__title--thank-you` }>
          Thank you for requesting a demo! &#10004;
        </h3>
        <p>
          Your demo request has been received successfully. Our team will contact
          you shortly to schedule a personalized demo session.
        </p>
        <p>
          We’re excited to show you how our solutions can transform your processes!
        </p>
      </div>
    );
  };


  renderFormSection() {
    return (
      <section className={ this.cn`__form__section __container` }>
        <div className={ this.cn`__form__half-container --left` }>
          { this.renderText() }
        </div>
        <div className={ this.cn`__form__map` }>
          { this.state.thankYou ? this.renderThankYou() :
          <div className={ this.cn`__form-container` }>
            <p>&#10132; Answer a few quick questions.</p>
            <br />
            <InternalForm onDone={ this.handleDone } />
          </div>
          }
        </div>

      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        { this.renderFormSection() }
      </div>
    );
  }
}
