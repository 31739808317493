/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import Resources from 'components/Resources';
import Cards from 'components/Cards';

import { TITLE, CONTENT, HOWITWORKS_TITLE, HOWITWORKS } from './constant';
import help from 'models/help';

export default class WireFraudPrevention extends BasePage {
  className = 'ts-WireFraudPrevention';
  static title = 'Wire Fraud Prevention';
  static description =
    'Trust Stamp secures your financial transactions against unauthorized access using an advanced, privacy-preserving multifactor authentication solution.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="globe" size="small">
          <h1>Wire Fraud Prevention</h1>
        </Banner>

        <section className={ this.cn`__documents` }>
          <div className={ this.cn`__documents-content ts-container` }>
            <div className={ this.cn`__documents-left-content` }>
              <h2>
                Protect Your Financial Transactions with Trust Stamp's Advanced
                Wire Fraud Prevention Technology
              </h2>
              <p className="ts-p">
                Wire transfer fraud is a growing challenge to the financial
                sector, causing substantial financial losses and eroding client
                trust in banking systems worldwide. Traditional security
                measures like knowledge-based authentication (KBA) and one-time
                passcodes (OTPs) are proving increasingly ineffective against
                advanced phishing and social engineering tactics. Trust Stamp
                offers a privacy-preserving authentication solution designed to
                secure wire transfer processes against unauthorized access
                through advanced multifactor authentication.
              </p>
              <Link to={ ROUTES.SCHEDULE_DEMO } className="ts-button--filled">
                Book demo
              </Link>
            </div>
            <div className={ this.cn`__documents-right-content` }>
              <img
                src={ asset`ai/wire.png` }
                alt="Trust Stamp - Age Estimation"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="ts-container">
            <h6>addressing key challenges</h6>
            <h2>The Problem: The Growing Threat of Wire Transfer Fraud</h2>
            <p className="ts-p">
              With the increased use of digital banking, wire transfer fraud has
              become more sophisticated and harder to trace. This type of fraud
              significantly impacts financial institutions and their clients,
              leading to direct financial losses, reputational damage, and
              diminished customer trust. The speed and irreversibility of wire
              transfers add to the challenge, making it nearly impossible to
              recover funds once a fraudulent transaction has occurred.
            </p>
            <Link
              to={ ROUTES.WIRE_WHITEPAPER }
              target="_blank"
              className="ts-button--filled"
            >
              GET THE WHITEPAPER
            </Link>
            <br />
            <br />
          </div>
        </section>

        <section>
          <div className="ts-container">
            <h6>THE SOLUTION</h6>
            <h2>{ HOWITWORKS_TITLE }</h2>
            <p className="ts-p">
              Trust Stamp's Stable IT2 technology offers a groundbreaking
              multifactor authentication solution designed to enhance the
              security of wire transfers. By integrating possession (something
              the user has), biometric (something the user is), and optionally,
              knowledge (something the user knows), Stable IT2 provides a robust
              defense against wire fraud.
            </p>
            <Cards content={ HOWITWORKS } columnCount={ 2 } />
          </div>
        </section>

        <Benefits
          subTitle="THE TECHNOLOGY"
          sectionTitle={ TITLE }
          content={ CONTENT }
          colored={ true }
        />

        <section>
          <div className="ts-container">
            <h6>GET IN TOUCH</h6>
            <h2>Secure Your Transactions Today</h2>
            Trust Stamp’s multi-factor authentication significantly advances
            wire transfer fraud prevention, offering financial institutions a
            powerful tool to safeguard operations and client assets. Adopting
            Trust Stamp's technology allows banks and financial entities to
            enhance transaction security, maintain system integrity, and restore
            client trust in digital banking.
            <br />
            <br />
            <Link to={ ROUTES.CONTACT_US } className="ts-button--secondary">
              GET IN TOUCH
            </Link>
          </div>
        </section>

        { /* resources section */ }
        <Resources>
          <article>
            <h6>RESOURCES</h6>
            <h2>Get the Trust Stamp’s Wire Fraud Whitepaper</h2>
            <p>
              Download our comprehensive whitepaper for an in-depth look at wire
              transfer fraud and Trust Stamp’s innovative solution.
            </p>
            <Link
              to={ ROUTES.WIRE_WHITEPAPER }
              target="_blank"
              className="ts-button--primary"
            >
              DOWNLOAD NOW
            </Link>
          </article>
          <aside>
            <img
              src={ asset`/ai/privacy-paper-3.svg` }
              alt="Privacy-Protecting Power of the Irreversibly Transformed Identity Token"
              width="400"
            />
          </aside>
        </Resources>
      </div>
    );
  }
}
