/* eslint-disable max-len */
import { ROUTES } from 'constants';
import React, { Fragment } from 'react';


export const TITLE = 'Stable IT2 Features & Benefits';

export const CONTENT = [
  {
    id: 1,
    title: 'Decentralized Authentication',
    text: 'Removes the need for centralized storage of sensitive data such as templates or private keys, safeguarding customer privacy and enterprise security by ensuring biometrics and keys are never stored with third parties.',
    slug: 'protect-data',
  },
  {
    id: 2,
    title: 'Cross-Platform Compatibility',
    text: 'Works with Web, Android and iOS devices, ensuring broad usability across different platforms.',
    slug: 'cross-platform',
  },
  {
    id: 3,
    title: 'Privacy-Centric Compliance',
    text: 'Adheres to GDPR and other privacy statutes, ensuring that user privacy is maintained and regulatory requirements are met.',
    slug: 'privacy-compliance',
  },
  {
    id: 4,
    title: 'Enhanced Security Beyond Passkeys',
    text: 'Stable IT2 provides superior security by not storing passkeys and requiring live biometric input for access, reducing risks associated with device compromise and cloud syncing.',
    slug: 'enhanced-security',
  },
  {
    id: 5,
    title: 'Future-Proof Digital Identity',
    text: 'Adheres to NIST SP-800 57 standards with cryptographic keys of 128 or 256 bits, ensuring robust protection against cryptanalysis and other attacks.',
    slug: 'future-proof',
  }
];

export const USE_CASES_TITLE = 'Stable IT2 Sample Use Cases';
export const USE_CASES = [
  {
    id: 1,
    title: 'Financial Institutions & Digital Wallets',
    text: 'Enhances security for digital transactions, ensuring compliance with regulatory standards like PSD2 and GDPR while improving user experience.',
    slug: 'financial_wallet',
  },
  {
    id: 2,
    title: 'Identity and Access Management',
    text: 'Provides a secure and seamless alternative to one-time passcodes and traditional MFA methods, significantly reducing the risk of phishing, SIM-swapping, and credential-stuffing attacks.',
    slug: 'multiple-checks-2',
  },
  {
    id: 3,
    title: 'Enhanced KYC Processes',
    text: 'Facilitates secure identity verification through cryptographic anchors generated during enrollment, enhancing trust and compliance in online transactions.',
    slug: 'authenticate-2',
  },
];

export const HOWITWORKS_TITLE = 'Trust Stamp’s Stable IT2 Technology';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Revolutionary Biometric Authentication',
    text: (
      <Fragment>
        Stable IT<sup>2</sup> eliminates the need to store biometric data and cryptographic keys, introducing a new approach to biometric authentication through a crypto-system that binds biometrics to private keys.
      </Fragment>
    ),
    slug: 'lady-face-scan',
  },
  {
    id: 2,
    title: 'Secure Cryptographic Key Storage',
    text: (
      <Fragment>
        Stable IT<sup>2</sup> utilizes facial recognition to encrypt private cryptographic keys securely.  The encrypted key is sharded, requiring a biometric to recompose the sharded key.
      </Fragment>
    ),
    slug: 'recomposition-of-key',
  },
  {
    id: 3,
    title: 'Recomposition of Keys',
    text: (
      <Fragment>
        Allows for the cryptographic key to be recomposed by the user presenting their face and the sharded pieces.
      </Fragment>
    ),
    slug: 'secure-key-storage',
  },
  {
    id: 4,
    title: 'Exclusive User Authentication',
    text: (
      <Fragment>
        Ensures that cryptographic keys are regenerated only by the authorized user's face and without relying on stored biometric data for authentication.
      </Fragment>
    ),
    slug: 'user-authentication',
  },
];

/* eslint-disable max-len */
export const VIDEO_CONTENT = {
  id: 1,
  subTitle: 'Stable IT2',
  title: 'Stable IT2: The Future of Secure Biometric Authentication',
  text: 'Stable IT2 is a groundbreaking advancement in biometric identity authentication. It offers secure identity verification without storing biometric data, redefining privacy and security in the digital world. By integrating an additional layer of biometric binding, Stable IT2 sets a new standard for identity authentication, aligning with and surpassing FIDO2 standards.',
  video: 'https://player.vimeo.com/video/958633239',
  link: ROUTES.SCHEDULE_DEMO,
  linkText: 'Book Demo',
};

